:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono:
    ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace',
    'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body > div {
  background: linear-gradient(to bottom, transparent, var(--bgColor-default)) var(--bgColor-muted);
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
